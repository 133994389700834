import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "wrapper-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, {
    class: "modal-breakpoint",
    ref: "modal",
    trigger: _ctx.trigger,
    backdropDismiss: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
        _createVNode(_component_ion_icon, {
          icon: _ctx.closeOutline,
          onClick: _ctx.onHide
        }, null, 8, ["icon", "onClick"])
      ]),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["trigger"]))
}