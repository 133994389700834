import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_app = _resolveComponent("ion-app")!
  const _component_photo_preview = _resolveComponent("photo-preview")!
  const _component_video_preview = _resolveComponent("video-preview")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_app, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_router_outlet, { id: "wrapper-app" })
      ]),
      _: 1
    }),
    (_ctx.showCamera || _ctx.photoPreview)
      ? (_openBlock(), _createBlock(_component_photo_preview, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.showVideo)
      ? (_openBlock(), _createBlock(_component_video_preview, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}