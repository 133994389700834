import { DURATION_VIDEO, VIDEO_CONFIG } from "@/views/video/config";
import { loadingController } from "@ionic/vue";
import RecordRTC from "recordrtc";
import "webrtc-adapter";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";
import { reactive, ref } from "vue";
import "webrtc-adapter";
// import JSZip from "jszip";

const video = reactive(VIDEO_CONFIG);
const timeDuration = ref(DURATION_VIDEO);
const durationInterval = ref();

const videoRef = ref();
const recordData = ref<File>();
const showVideo = ref(false);
const isShowingCamera = ref(false);
const isRecording = ref(false);
const isTakeVideo = ref(false);
const isPlaying = ref(false);
const timeout = ref();

export const useVideo = () => {
  const getDevice = async () => {
    showVideo.value = true;
    if (videoRef.value) {
      await videoRef.value.record().getDevice();
      isShowingCamera.value = true;
      loadingController.dismiss();
    }
  };

  const startRecording = async () => {
    // file.startRecord();
    video.isSaveDisabled = true;
    video.isRetakeDisabled = true;
    isRecording.value = true;
    video.retake += 1;
    await videoRef.value.record().start();
  };

  const stopRecording = async () => {
    showVideo.value = false;
    await videoRef.value.record().stop();
  };

  const onPlayVideo = async () => {
    if (recordData.value && !isPlaying.value) {
      isPlaying.value = true;
      await videoRef.value.play();

      timeout.value = setTimeout(() => {
        isPlaying.value = false;
      }, DURATION_VIDEO * 1000);
    }
  };

  const onFinishRecord = () => {
    videoRef.value.on("finishRecord", () => {
      recordData.value = new File([videoRef.value.recordedData], "video") as File;
      isRecording.value = false;
    });
  };

  const reTakeVideo = () => {
    recordData.value = undefined;
    isPlaying.value = false;
    timeDuration.value = DURATION_VIDEO;
    startRecording();
  };

  const takeVideo = () => {
    isPlaying.value = false;
    isTakeVideo.value = true;
    stopRecording();
  };

  const onDestroy = () => {
    videoRef.value.dispose();
    recordData.value = undefined;
    showVideo.value = false;
    isRecording.value = false;
    isTakeVideo.value = false;
    isShowingCamera.value = false;
  };

  return {
    recordData,
    videoRef,
    video,
    showVideo,
    isRecording,
    isTakeVideo,
    isShowingCamera,
    timeDuration,
    durationInterval,
    isPlaying,
    getDevice,
    startRecording,
    onFinishRecord,
    onPlayVideo,
    takeVideo,
    stopRecording,
    onDestroy,
    reTakeVideo,
  };
};
