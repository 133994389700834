import axios from "axios";

const REQUEST_CODE_LOGIN_URL = process.env.VUE_APP_URL + "/customer/login/create-otp";
const REQUEST_LOGIN_URL = process.env.VUE_APP_URL + "/customer/login/verify-otp";

type requestCode = {
  phone: string;
  countryCode: string;
};
type requestLogin = {
  otpCode: string;
  phone: string;
  otpToken: string;
};
export const requestCodeLogin = (params: requestCode) => {
  const requestBody = { ...params };

  return axios({
    url: REQUEST_CODE_LOGIN_URL,
    method: "post",
    data: requestBody,
  });
};

export const requestLogin = (params: requestLogin) => {
  const requestBody = { ...params };

  return axios({
    url: REQUEST_LOGIN_URL,
    method: "post",
    data: requestBody,
  });
};
