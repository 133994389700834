import { Locales } from "./locales";

import en from "./en.json";
import vn from "./vn.json";
import kr from "./kr.json";

export const messages = {
    [Locales.EN]: en,
    [Locales.KR]: kr,
    [Locales.VN]: vn,
};

export const defaultLocale = Locales.EN;