
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import { usePhoto } from "./helpers/usePhoto";
import { useVideo } from "./helpers/useVideo";
import PhotoPreview from "./views/photo/PhotoPage.vue";
import VideoPreview from "./views/video/VideoPage.vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    PhotoPreview,
    VideoPreview,
  },

  setup() {
    const { showCamera, photoPreview } = usePhoto();
    const { showVideo } = useVideo();
    return {
      showCamera,
      photoPreview,
      showVideo,
    };
  },
});
