import UserLoginPageView from "@/views/auth/user/UserLoginPage.vue";
import AdminLoginPageView from "@/views/auth/AdminLoginPage.vue";

export const routesAuth = [
  {
    path: "/auth/login/admin",
    name: "adminLogin",
    component: AdminLoginPageView,
  },
  {
    path: "/auth/login/user",
    name: "userLogin",
    component: UserLoginPageView,
  },
];
