import { usePhoto } from "@/helpers/usePhoto";
import { cameraReverseOutline } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CameraPage",
  components: {},
  setup() {
    const {
      capturePhoto,
      photoPreview,
      stopCamera,
      resetCapture,
      takePhoto,
      showCamera,
      information,
      isOpenCamera,
      openFrontCam,
      switchCam,
    } = usePhoto();

    return {
      capturePhoto,
      stopCamera,
      resetCapture,
      takePhoto,
      photoPreview,
      showCamera,
      information,
      isOpenCamera,
      openFrontCam,
      switchCam,
      cameraReverseOutline,
    };
  },
});
