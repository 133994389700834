<template>
  <div class="wrapper-video">
    <div class="video-wrapper" @click="onPlayVideo">
      <video id="myVideo" playsinline class="video-js vjs-default-skin"></video>
    </div>
    <div class="camera-content">
      <p class="description text-center" v-show="recordData && !isPlaying">
        <ion-icon :icon="playOutline" @click="onPlayVideo" class="text-xl" />
      </p>
      <p class="description text-center" v-if="!recordData && !isPlaying">{{ timeDuration }}s</p>
      <p class="description text-center">
        {{ intl("kyc.kyc_video_warning") }}
      </p>
    </div>
    <div class="actions text-center w-full flex flex-wrap items-center justify-around">
      <button
        v-if="!recordData && !isRecording && isShowingCamera"
        class="recording"
        @click="startRecording"
      ></button>
      <button v-if="recordData" class="use-photo mb-2" @click="takeVideo">Use video</button>
      <button v-if="recordData" class="use-photo mb-2" @click="reTakeVideo">Retake</button>
    </div>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";
import "webrtc-adapter";
import RecordRTC from "recordrtc";
import Record from "videojs-record/dist/videojs.record.js";
import FFmpegjsEngine from "videojs-record/dist/plugins/videojs.record.ffmpegjs.js";
import { defineComponent, onMounted, onUnmounted, ref, watch, watchEffect } from "vue";
import { useVideo } from "@/helpers/useVideo";
import { DURATION_VIDEO } from "@/views/video/config";
import { useI18n } from "vue-i18n";
import videojs from "video.js";
import { playOutline } from "ionicons/icons";

export default defineComponent({
  name: "VideoPage",
  components: {},
  setup() {
    const { t: intl } = useI18n();

    const {
      showVideo,
      recordData,
      videoRef,
      isRecording,
      isShowingCamera,
      video,
      durationInterval,
      timeDuration,
      isPlaying,
      getDevice,
      onPlayVideo,
      startRecording,
      onFinishRecord,
      takeVideo,
      reTakeVideo,
    } = useVideo();

    watchEffect(() => {
      if (!isRecording.value) return;

      durationInterval.value = setInterval(() => {
        if (timeDuration.value === 0) {
          isPlaying.value = false;
          clearInterval(durationInterval.value);
          return;
        } else {
          timeDuration.value--;
        }
      }, 1000);
    });

    onMounted(async () => {
      if (showVideo) {
        videoRef.value = await videojs("#myVideo", video.options);
        getDevice();
      }
      onFinishRecord();
    });

    return {
      onPlayVideo,
      reTakeVideo,
      startRecording,
      takeVideo,
      intl,
      isPlaying,
      isShowingCamera,
      recordData,
      videoRef,
      timeDuration,
      isRecording,
      playOutline,
    };
  },
});
</script>

<style lang="scss" src="./video.scss"></style>
