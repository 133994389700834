import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

import { routesAuth } from "./auth";

// ERROR PAGE
import NotFoundPageView from "@/views/error/404Page.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/views/verification/VerificationPage.vue"),
  },

  ...routesAuth,
  // //When route not exist return page not found
  {
    path: "/:catchAll(.*)",
    name: "Not found",
    component: NotFoundPageView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.fullPath === "/tabs/dashboard" && from.fullPath === "/auth/login/user") {
//     next();
//   } else if (to.fullPath !== "/auth/login/user" && !store.getters.auth.token) {
//     next({ path: "/auth/login/user" });
//   } else next();
// });

export default router;
