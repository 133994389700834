
import { IonIcon, IonModal } from "@ionic/vue";
import { closeOutline } from "ionicons/icons";
import { defineComponent, ref, onMounted } from "vue";

export default defineComponent({
  name: "ModalBreakpoint",
  props: ["trigger", "initialBreakpoint", "title", "dismiss"],
  components: {
    IonModal,
    IonIcon,
  },
  setup(props) {
    const modal = ref();
    const onHide = () => {
      modal.value.$el.dismiss();
      props.dismiss && props.dismiss();
    };

    return {
      modal,
      closeOutline,
      onHide,
    };
  },
});
